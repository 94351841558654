import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../components/logo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{ width: `45em`, margin: `auto` }}><Logo /></div>
    
  </Layout>
)

export default IndexPage
